























import Component, { mixins } from "vue-class-component";
import ErrorPageMixin from "@/mixins/ErrorPageMixin";

@Component
export default class PageToManyRequests extends mixins(ErrorPageMixin) {}
